<template>
<g-invoice action-hooks="updateInvoice"/>
</template>

<script>
import GInvoice from "../../components/GInvoice";
export default {
  name: "EditInvoice",
  components: {GInvoice},
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Panel - Edit Invoice | %s',
    meta: [
      {name: 'robots', content: 'noindex'},
      {name: 'googlebot', content: 'noindex'}
    ]
  }
}
</script>